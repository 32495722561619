<template>
  <search-item
    :item="item"
    no-doc-ref
  >
    <div class="d-flex align-center font-weight-bold">
      <div class="d-flex lightened-invoice-number">
        <span class="mr-1">{{search.columns[0].text}}</span>
        <column-mapper
          :column="search.columns[0]"
          :value="item['c0']"
        />
      </div>
      <v-spacer />
      <column-mapper
        :column="search.columns[4]"
        :value="item['c4']"
      />
    </div>
    <div class="d-flex align-center">
      <span class="text--disabled">{{search.columns[1].text}}</span>
      <v-spacer />
      <column-mapper
        :column="search.columns[1]"
        :value="item['c1']"
      />
    </div>
    <div class="d-flex align-center">
      <span class="text--disabled">{{search.columns[2].text}}</span>
      <v-spacer />
      <column-mapper
        :column="search.columns[2]"
        :value="item['c2']"
      />
    </div>
    <div class="d-flex align-center">
      <span class="text--disabled">{{search.columns[3].text}}</span>
      <v-spacer />
      <column-mapper
        class="my-n2"
        :column="search.columns[3]"
        :value="item['c3']"
        :icon-props="{small: true}"
      />
    </div>
  </search-item>
</template>

<script>
import Search from '@/pages/search/controllers'

export default {
  name: 'search-item-invoices',
  inheritAttrs: false,
  components: {
    ColumnMapper: () => import('@/pages/search/components/column-mapper'),
    SearchItem: () => import('@/pages/mobility/components/search-item')
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    search: {
      required: true,
      type: Search
    }
  }
}
</script>

<!-- This is a hack to hide the "navigate to" in mobility, this should disappear -->
<style lang="stylus" scoped>
.lightened-invoice-number
  opacity 0.8

>>> .v-btn.v-btn--icon
  display none
</style>
