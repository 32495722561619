import { render, staticRenderFns } from "./search-item-invoices.vue?vue&type=template&id=5d8c4a11&scoped=true&"
import script from "./search-item-invoices.vue?vue&type=script&lang=js&"
export * from "./search-item-invoices.vue?vue&type=script&lang=js&"
import style0 from "./search-item-invoices.vue?vue&type=style&index=0&id=5d8c4a11&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d8c4a11",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VSpacer})
